import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import GreenLoader from "../../../../animation/blinker.json";
import ContactForm from "../../../common/ContactForm";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [visible, setVisible] = useState(false);
  const [modalTestEmail, setModalTestEmail] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
  };

  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const bookDemo = () => {
    if (location.pathname === "/book-a-demo") {
      scrollToTop();
    } else {
      navigate("/book-a-demo");
    }
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <>
      <section>
        <div className="footer-wrapper">
          <div className="container">
            <div className="inner-footer">
              <div className="footer-menu">
                <div className="row">
                  <div className="col-md-3">
                    <div className="ft-wrapper">
                      <div className="footer-logo-wrap">
                        <img
                          className="footer-img"
                          src="./images/footer-logo.webp"
                          alt="FrrightGain"
                          title="FrrightGain"
                        />
                      </div>
                      <h4 className="get-in-touch">
                        Get in touch for a <br /> detailed demo.
                      </h4>
                      <div className="orange-line-wrap">
                        <img
                          src="./images/orange-line.svg"
                          alt="Orange Divide"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ft-wrapper">
                      <h5>Reach Us</h5>
                      <ul className="reach-us">
                        <li>
                          <span className="font-icon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                          </span>{" "}
                          <span className="content-text">
                            611, Techno IT Park, Near Eskay Resort, Link Road,
                            Borivali (West), Mumbai - 400092, Maharashtra,
                            India.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-2 pad992">
                    <div className="ft-wrapper contact-us-wrap">
                      <h5>Contact Us</h5>
                      <ul>
                        <li>
                          <span className="font-icon">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <a href="tel:919819882328" title="Call Us">
                            <span className="content-text">+91-9819882328</span>
                          </a>
                        </li>
                        <li>
                          <span className="font-icon">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <a
                            href="mailto:sales@freightgain.com"
                            title="Email Us"
                          >
                            <span className="content-text">
                              sales@freightgain.com
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 pad850right">
                    <div className="ft-wrapper book-demo-footer">
                      <h5>Book Demo</h5>
                      <div className="text-center mb-5">
                        <button
                          className="start-driving-btn"
                          onClick={() => bookDemo()}
                        >
                          <Lottie
                            autoPlay="true"
                            loop="true"
                            className="glow-icon"
                            animationData={GreenLoader}
                          />
                          <span className="btn-text">Book a Demo</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="https://api.whatsapp.com/send?phone=919819882328"
                  target="_blank"
                  title="WhatsApp Now"
                  className="whatsapp-now-float"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
                {showTopBtn && (
                  <button
                    title="scroll to top"
                    className="scroll-top"
                    onClick={scrollToTop}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="copywrite-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-8 text-left left-copywrite">
                <p>
                  {new Date().getFullYear()} © FreightGain Online Technolgies
                  Pvt Ltd. All Rights Reserved.
                </p>
              </div>
              <div className="col-md-4 text-right right-copywrite">
                <ul>
                  {/* <li>
                    <Link to="/" title="Disclaimer">
                      Disclaimer
                    </Link>
                  </li>

                  <li>
                    <Link to="/" title="Terms of Use">
                      Terms of Use
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/privacypolicy" title="Privacy Policy">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm
        modalTestEmail={modalTestEmail}
        setModalTestEmail={setModalTestEmail}
      />
    </>
  );
};

export default Footer;
