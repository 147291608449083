import React from "react";
import "./App.css";
import Root from "./routes/Root";
import "./scss/style.scss";
import "./scss/responsive.scss";



function App() {
  return (
    <>
      <div className="App">
          <Root/>
      </div>
    </>
  );
}

export default App;
