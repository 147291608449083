import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section>
        <div className="privacy-wrapper">
            <div className="common-head-wrap">
              <h1>Privacy Policy</h1>
            </div>

          <div className="mid-content-wrap">
            <div className="container">
              <h2 className="sub-heading">Introduction</h2>
              <p>
                Freightgain respects the privacy of our
                users (“user” or “you”). This Privacy Policy explains how we
                collect, use, disclose, and safeguard your information when you
                visit our website www.freightgain.com and our mobile application,
                including any other media form, media channel, mobile website,
                or mobile application related or connected thereto
                (collectively, the “Site”). Please read this privacy policy
                carefully. If you do not agree with the terms of this privacy
                policy, please do not access the site. We reserve the right to
                make changes to this Privacy Policy at any time and for any
                reason. We will alert you about any changes by updating the
                “Last Updated” date of this Privacy Policy. Any changes or
                modifications will be effective immediately upon posting the
                updated Privacy Policy on the Site, and you waive the right to
                receive specific notice of each such change or modification. You
                are encouraged to periodically review this Privacy Policy to
                stay informed of updates. You will be deemed to have been made
                aware of, will be subject to, and will be deemed to have
                accepted the changes in any revised Privacy Policy by your
                continued use of the Site after the date such revised Privacy
                Policy is posted.
              </p>
              <br />
              <h2 className="sub-heading">Collection of your information</h2>
              <p>
                We may collect information about you in a variety of ways. The
                information we may collect on the Site includes:
              </p>
              <br />
              <h2 className="sub-heading">Personal data</h2>
              <p>
                Personally identifiable information, such as your name, shipping
                address, email address, and telephone number, and demographic
                information that you voluntarily give to us when you register
                with the Site or when you choose to participate in various
                activities related to the Site. You are under no obligation to
                provide us with personal information of any kind, however your
                refusal to do so may prevent you from using certain features of
                the Site and our mobile application]
              </p>
              <br />
              <h2 className="sub-heading">Derivative data</h2>
              <p>
                Information our servers automatically collect when you access
                the Site, such as your IP address, your browser type, your
                operating system, your access times, and the pages you have
                viewed directly before and after accessing the Site. If you are
                using our mobile application, this information may also include
                your device name and type, your operating system, your phone
                number, your country, your likes and replies to a post, and
                other interactions with the application and other users via
                server log files, as well as any other information you choose to
                provide.
              </p>
              <br />
              <h2 className="sub-heading">Financial data</h2>
              <p>
                Financial information, such as data related to your payment
                method (e.g. valid credit card number, card brand, expiration
                date) that we may collect when you purchase, order, return,
                exchange, or request information about our services from the
                Site or our mobile application] We store only very limited, if
                any, financial information that we collect. Otherwise, all
                financial information is stored by our payment processors.
              </p>
              <br />
              <h2 className="sub-heading">Mobile device data</h2>
              <p>
                Device information, such as your mobile device ID, model, and
                manufacturer, and information about the location of your device,
                if you access the Site from a mobile device.
              </p>
              <br />
              <h2 className="sub-heading">Third-party data</h2>
              <p>
                Information from third parties, such as personal information or
                network friends, if you connect your account to the third party
                and grant the Site permission to access this information.
              </p>
              <br />
              <h2 className="sub-heading">Use of your information</h2>

              <p>
                Having accurate information about you permits us to provide you
                with a smooth, efficient, and customized experience.
                Specifically, we may use information collected about you via the
                Site or our mobile application to:
              </p>
              <br />
              <h2 className="sub-heading">Disclosure of your information</h2>
              <p>
                We may share information we have collected about you in certain
                situations. Your information may be disclosed as follows:
              </p>
              <br />
              <h2 className="sub-heading">By law or to protect rights</h2>
              <p>
                If we believe the release of information about you is necessary
                to respond to legal process, to investigate or remedy potential
                violations of our policies, or to protect the rights, property,
                and safety of others, we may share your information as permitted
                or required by any applicable law, rule, or regulation. This
                includes exchanging information with other entities for fraud
                protection and credit risk reduction.
              </p>
              <br />
              <h2 className="sub-heading">Third-Party service providers</h2>
              <p>
                We may share your information with third parties that perform
                services for us or on our behalf, including payment processing,
                data analysis, email delivery, hosting services, customer
                service, and marketing assistance.
              </p>

              <br />
              <h2 className="sub-heading">Marketing communications</h2>
              <p>
                With your consent, or with an opportunity for you to withdraw
                consent, we may share your information with third parties for
                marketing purposes, as permitted by law.
              </p>
              <br />
              <h2 className="sub-heading">Interactions with other users</h2>
              <p>
                If you interact with other users of the Site and our mobile
                application, those users may see your name, profile photo, and
                descriptions of your activity, including sending invitations to
                other users, chatting with other users, liking posts, following
                blogs.
              </p>
              <br />
              <h2 className="sub-heading">Cookies and web beacons</h2>
              <p>
                We may use cookies, web beacons, tracking pixels, and other
                tracking technologies on the Site and our mobile application to
                help customize the Site and our mobile application and improve
                your experience. When you access the Site or our mobile
                application, your personal information is not collected through
                the use of tracking technology. Most browsers are set to accept
                cookies by default. You can remove or reject cookies, but be
                aware that such action could affect the availability and
                functionality of the Site or our mobile application. You may not
                decline web beacons. However, they can be rendered ineffective
                by declining all cookies or by modifying your web browser’s
                settings to notify you each time a cookie is tendered,
                permitting you to accept or decline cookies on an individual
                basis.
              </p>
              <br />
              <h2 className="sub-heading">Internet-based advertising</h2>
              <p>
                Additionally, we may use third-party software to serve ads on
                the Site and our mobile application, implement email marketing
                campaigns, and manage other interactive marketing initiatives.
              </p>
              <br />
              <h2 className="sub-heading">Website analytics</h2>
              <p>
                We may also partner with selected third-party vendors to allow
                tracking technologies and remarketing services on the Site and
                our mobile application through the use of first party cookies
                and third-party cookies, to, among other things, analyze and
                track users’ use of the Site and our mobile application,
                determine the popularity of certain content and better
                understand online activity. By accessing the Site, or our mobile
                application, you consent to the collection and use of your
                information by these third-party vendors. You are encouraged to
                review their privacy policy and contact them directly for
                responses to your questions. We do not transfer personal
                information to these third-party vendors.
              </p>

              <p>
                You should be aware that getting a new computer, installing a
                new browser, upgrading an existing browser, or erasing or
                otherwise altering your browser’s cookies files may also clear
                certain opt-out cookies, plug-ins, or settings.
              </p>
              <br />
              <h2 className="sub-heading">Third party website</h2>
              <p>
                The Site and our mobile application may contain links to
                third-party websites and applications of interest, including
                advertisements and external services, that are not affiliated
                with us. Once you have used these links to leave the Site or our
                mobile application, any information you provide to these third
                parties is not covered by this Privacy Policy, and we cannot
                guarantee the safety and privacy of your information. Before
                visiting and providing any information to any third-party
                websites, you should inform yourself of the privacy policies and
                practices (if any) of the third party responsible for that
                website, and should take those steps necessary to, in your
                discretion, protect the privacy of your information. We are not
                responsible for the content or privacy and security practices
                and policies of any third parties, including other sites,
                services or applications that may be linked to or from the Site
                or our mobile application.
              </p>
              <br />

              <h2 className="sub-heading">Security of your information</h2>
              <p>
                We use administrative, technical, and physical security measures
                to help protect your personal information. While we have taken
                reasonable steps to secure the personal information you provide
                to us, please be aware that despite our efforts, no security
                measures are perfect or impenetrable, and no method of data
                transmission can be guaranteed against any interception or other
                type of misuse. Any information disclosed online is vulnerable
                to interception and misuse by unauthorized parties. Therefore,
                we cannot guarantee complete security if you provide personal
                information.
              </p>
              <br />
              <h2 className="sub-heading">Policy for children </h2>
              <p>
                We do not knowingly solicit information from or market to
                children under the age of 13. If you become aware of any data we
                have collected from children under age 13, please contact us
                using the contact information provided below.
              </p>
              <br />
              <h2 className="sub-heading">Controls for DO-NOT-TRICK features</h2>
              <p>
                Most web browsers and some mobile operating systems and our
                mobile applications include a Do-Not-Track (“DNT”) feature or
                setting you can activate to signal your privacy preference not
                to have data about your online browsing activities monitored and
                collected. No uniform technology standard for recognizing and
                implementing DNT signals has been finalized. As such, we do not
                currently respond to DNT browser signals or any other mechanism
                that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we
                must follow in the future, we will inform you about that
                practice in a revised version of this Privacy Policy./Most web
                browsers and some mobile operating systems and our mobile
                applications include a Do-Not-Track (“DNT”) feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. If you set the DNT signal on your browser, we will
                respond to such DNT browser signals.
              </p>
              <br />
              <h2 className="sub-heading">Contact us</h2>
              <p>
                If you have questions or comments about this Privacy Policy,
                please contact us at
                <a href="mailto:service@trago.com" title="Mail Us">service@trago.com</a>
              </p>
          
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
