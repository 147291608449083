import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CiLogin } from "react-icons/ci";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className={`custom-navbar ${scroll ? "scrolled-fixed" : ""}`}>
        <div className="container">
          <Navbar expand="lg">
            <NavbarBrand href="/">
              <img
                src="./images/logo.webp"
                alt="FreightGain"
                title="FreightGain"
              />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {/* <NavItem>
                  <NavLink href="/components/" title="Products">
                    Products
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink href="/components/" title="Services">
                    Services
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink href="/components/" title="Pricing">
                    Pricing
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink href="/components/" title="Contact Us">
                    Contact Us
                  </NavLink>
                </NavItem> */}
                {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav title="Solution">
                  Solutions
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Option 1</DropdownItem>
                  <DropdownItem>Option 2</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
                {/* <NavItem>
                  <Link className="top-nav-btn mr-2" to="#" title="Login">
                    Login
                  </Link>
                  <Link className="top-nav-btn" to="#" title="Register">
                    Register
                  </Link>
                </NavItem> */}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Header;
